import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`It looks like the Treasury is seeking $50 million from Congress to `}<a parentName="p" {...{
        "href": "http://online.wsj.com/article/SB10001424052748703321004575427884043641038.html"
      }}>{`nationalize the Bank On USA program`}</a>{`, as reported in the Wall Street Journal.`}</p>
    <p>{`These government efforts are working towards the same goal as PayPerks, to help workers without access to bank accounts avoid the high costs of cashing checks:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Workers without bank accounts typically spend hundreds of dollars a year to cash checks. An unbanked worker can spend \\$41,600 over a lifetime in check-cashing fees, the Brookings Institution, a liberal think tank, estimated in 2008.`}</p>
    </blockquote>
    <p>{`I’m surprised to see the banks participating this initiative. Banks do not generally like to offer accounts to individuals who maintain low balances as these accounts are costly for the banks to maintain and service.  Maybe the banks do not plan to make money on these accounts, but rather are just doing it for the `}<a parentName="p" {...{
        "href": "http://en.wikipedia.org/wiki/Community_Reinvestment_Act"
      }}>{`CRA credits`}</a>{`?`}</p>
    <p>{`It's a great thing for more workers to have access to bank accounts. But rather than create a tax-payer funded scheme to promote accounts the banks don't want to have in the first place, we encourage everyone to take a closer look at Payroll Cards.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      